import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage, getImage } from "gatsby-plugin-image"
import TextRight from "../components/TextRight"

const LogHomes = ( {data} ) => {

  const logHomeDesign = getImage(data.logHomeDesign)
  const logHomePackages = getImage(data.logHomePackages)
  const logHomeTypes = getImage(data.logHomeTypes)
  const logHomeDoors = getImage(data.logHomeDoors)

  return (
    <Layout formTitle="Let us know how we can help create your dream log home.">
      <Seo 
        title="Custom Designed Log Homes"
        description="An Authentic Log Home is the perfect choice if you value quality, affordability, and truly personal custom design." 
      />
      <div className="container mx-auto pt-32 md:pt-48">
        <div className="relative flex items-center justify-center bg-header overflow-hidden">
          <h1 className="text-white px-6 py-32 md:px-12 md:py-64">Custom Authentic Log Homes</h1>
          <StaticImage
            src="../images/log_homes.jpg"
            alt="a custom log home in Vermont"
            placeholder="blurred"
            quality={100}
            className="header-bg-img"
          />
        </div>
      </div>
      <div className="container mx-auto mt-16 md:mt-32">
        <h2 className="md:text-center">Let’s create your dream log home, together.</h2>
        <p className="md:text-center md:max-w-4xl md:mx-auto">An Authentic Log Home is the perfect choice if you value quality, affordability, and truly personal custom design. We will work one-on-one with you throughout the entire home design process, and we will be here when you have questions and concerns after the sale. The homes we sell are the dreams and inspiration of our customers.</p>
        <TextRight
          preHeading="Step One"
          heading={<h2>Designing your log home</h2>}
          subHeading={<p>We will work with you and your builders, contractors, and/or architects to help create the log home of your dreams. This custom design and personal attention comes at no extra cost and enables us to help you match the home of your dreams with your budget.</p>}
          image={logHomeDesign}
          altText="log home design drawings on a table"
          addedClasses="md:items-center mt-32 md:mt-40"
        />
        <TextRight
          preHeading="Step Two"
          heading={<h2>Choose a Log Home Package</h2>}
          subHeading={<p>We offer two Authentic Log Home packages to choose from: shell or complete. A shell package consists of your homes exterior walls, a roof system, doors, and windows. A complete package is everything included in the shell package plus interior walls, floors, doors, stair systems, insulation and more.</p>}
          image={logHomePackages}
          altText="a custom log home in Vermont"
          buttonClass="secondary-btn"
          buttonText="View log home packages"
          buttonLink="/log-home-packages/"
          addedClasses="md:items-center mt-32 md:mt-40"
        />
        <TextRight
          preHeading="Step Three"
          heading={<h3>Select Log Type &amp; Style</h3>}
          subHeading={<p>Once you decide on a log package, you will need to choose a log type, size, and profile style.</p>}
          image={logHomeTypes}
          altText="a pile of logs"
          buttonClass="secondary-btn"
          buttonText="View log types &amp; styles"
          buttonLink="/log-home-types/"
          addedClasses="md:items-center mt-32 md:mt-40"
        />
        <TextRight
          preHeading="Step Four"
          heading={<h3>Choose Doors &amp; Windows</h3>}
          subHeading={<p>We offer a wide variety of windows and doors for your log home.</p>}
          image={logHomeDoors}
          altText="a custom log home in Vermont"
          buttonClass="secondary-btn"
          buttonText="View log types &amp; styles"
          buttonLink="/log-home-doors-and-windows/"
          addedClasses="md:items-center mt-32 md:mt-40"
        />
      </div>
    </Layout>
  );
}
 
export default LogHomes;

export const pageQuery = graphql`
  query {
    logHomeDesign: file(relativePath: { eq: "log_home_design.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          )
      }
    },
    logHomePackages: file(relativePath: { eq: "log_home_packages.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          )
      }
    },
    logHomeTypes: file(relativePath: { eq: "log_home_types.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          )
      }
    },
    logHomeDoors: file(relativePath: { eq: "log_home_doors.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          )
      }
    },
  }
`